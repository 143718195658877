@import 'reset-1588842872.css';
@import 'typography-1588842872.css';
@import 'antd-1588842872.css';

.sl-top-bar {
  text-align: right;
  padding: 7px 24px;
  background-color: rgb(0 143 160);
  position: relative;
  z-index: 2;
}

.sl-top-bar-container {
  display: box;
  display: flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 100%;
}

.sl-top-bar-content {
  color: #fff;
  font-weight: 500;
  margin-right: 1em;
}

.sl-top-bar-actions a {
  background-color: rgb(245 185 64);
  padding: 4px 20px;
  color: #333;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 14px;
  width: 100%;
  height: 100%;
  display: box;
  display: flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
  text-align: center;
}

@media only screen and (max-width: 840px) {
  .sl-top-bar-container {
    text-align: left;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 600px) {
  .sl-top-bar-container {
    text-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    flex-direction: column;
    padding-bottom: 4px;
  }

  .sl-top-bar-actions a {
    margin-top: 8px;
  }
}

.sl-tooltip {
  background-color: #098ea0 !important;
  color: #fff !important;
  border: 1px solid #067886 !important;
}

.sl-tooltip > .tooltip-arrow {
  border-top: 5px solid #067886 !important;
}
