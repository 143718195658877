/* START BADGE */
@keyframes antStatusProcessing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}

@keyframes antStatusProcessing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}

/* END BADGE */

/* START SELECT */
@keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }

  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

@keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }

  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

@keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }

  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

@keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }

  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

@keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }

  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}

@keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }

  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}

@keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }

  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}

@keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }

  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}

.ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: rgb(0 0 0 / 65%);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  overflow: hidden;
  font-size: 14px;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}

.ant-select-item {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgb(0 0 0 / 65%);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.ant-select-item-option {
  display: flex;
  height: 48px;
  -webkit-box-pack: center;
  justify-content: center;
  -ms-flex-pack: center;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.ant-select-title-option {
  height: 106px;
}

.ant-select-main_category-option {
  height: 86px;
}

.ant-select-item-option-content {
  -webkit-box-flex: 1;
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-select-title-option .ant-select-item-option-content {
  height: 100%;
}

.ant-select-main_category-option .ant-select-item-option-content {
  height: 100%;
}

.ant-select-item-option-state {
  -webkit-box-flex: 0;
  flex: none;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #e0f1f3;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgb(0 0 0 / 85%);
  font-weight: 600;
  background-color: #e0f1f3;
}

.ant-select-dropdown-rtl {
  direction: rtl;
}

.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antslideupin;
  animation-name: antSlideUpIn;
}

.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  animation-name: antslidedownin;
  animation-name: antSlideDownIn;
}

.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antslideupout;
  animation-name: antSlideUpOut;
}

.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  animation-name: antslidedownout;
  animation-name: antSlideDownOut;
}

.ant-select-dropdown-hidden {
  display: none;
}

.ant-empty-small {
  margin: 8px 0;
  color: rgb(0 0 0 / 25%);
}

.ant-empty-normal {
  margin: 32px 0;
  color: rgb(0 0 0 / 25%);
}

.ant-empty {
  margin: 0 8px;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}

.ant-empty-small .ant-empty-image {
  height: 35px;
}

.ant-empty-normal .ant-empty-image {
  height: 40px;
}

.ant-empty-image {
  height: 100px;
  margin-bottom: 8px;
}

.ant-empty-description {
  margin: 0;
}

.ant-empty-image svg {
  height: 100%;
  margin: auto;
}

svg:not(:root) {
  overflow: hidden;
}

.ant-empty-img-simple-ellipse {
  fill: #e0f1f3;
}

.ant-empty-img-simple-g {
  stroke: #d9d9d9;
}

.ant-empty-img-simple-path {
  fill: #fafafa;
}

/* END SELECT */

/* START DATE PICKER */
.ant-picker-today-btn {
  color: #e0f1f3;
}

.ant-picker-today-btn:hover {
  color: #067786;
}

.ant-picker-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgb(0 0 0 / 65%);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  z-index: 1050;
}

.ant-picker-dropdown-range {
  padding: 6.6667px 0;
}

.ant-picker-range-wrapper {
  display: flex;
}

.ant-picker-range-arrow {
  position: absolute;
  z-index: 1;
  display: none;
  width: 10px;
  height: 10px;
  margin-left: 16.5px;
  transition: left 0.3s ease-out;
}

.ant-picker-panel-container {
  overflow: hidden;
  vertical-align: top;
  background: #fff;
}

.ant-picker-panel-container .ant-picker-panels {
  display: inline-box;
  display: inline-flexbox;
  display: inline-flex;
  flex-wrap: nowrap;
}

@media screen and (max-width: 768px) {
  .ant-picker-range-wrapper {
    width: 280px;
  }

  .ant-picker-panel-container .ant-picker-panels {
    flex-wrap: wrap;
  }
}

.ant-picker-panel {
  display: inline-block;
  text-align: center;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  outline: none;
}

.ant-picker-panel-container .ant-picker-panel {
  vertical-align: top;
  background: transparent;
  border-width: 0 0 1px;
  border-radius: 0;
}

.ant-picker-decade-panel,
.ant-picker-year-panel,
.ant-picker-month-panel,
.ant-picker-week-panel,
.ant-picker-date-panel,
.ant-picker-time-panel {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  width: 280px;
}

.ant-picker-header {
  display: flex;
  padding: 0 8px;
  color: rgb(0 0 0 / 85%);
  border-bottom: 1px solid #f0f0f0;
}

.ant-picker-header button {
  padding: 0;
  color: rgb(0 0 0 / 25%);
  line-height: 40px;
  background: transparent;
  border: 0;
  cursor: pointer;
  transition: color 0.3s;
}

.ant-picker-header > button {
  min-width: 1.6em;
  font-size: 14px;
}

.ant-picker-prev-icon,
.ant-picker-next-icon,
.ant-picker-super-prev-icon,
.ant-picker-super-next-icon {
  position: relative;
  display: inline-block;
  width: 7px;
  height: 7px;
}

.ant-picker-prev-icon,
.ant-picker-super-prev-icon {
  transform: rotate(-45deg);
}

.ant-picker-header-view {
  -webkit-box-flex: 1;
  flex: auto;
  font-weight: 500;
  line-height: 40px;
}

.ant-picker-header-view button {
  color: inherit;
  font-weight: inherit;
}

.ant-picker-header-view button:not(:first-child) {
  margin-left: 8px;
}

.ant-picker-prev-icon::before,
.ant-picker-next-icon::before,
.ant-picker-super-prev-icon::before,
.ant-picker-super-next-icon::before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentcolor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}

.ant-picker-super-prev-icon::after,
.ant-picker-super-next-icon::after {
  position: absolute;
  top: 4px;
  left: 4px;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentcolor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}

.ant-picker-next-icon,
.ant-picker-super-next-icon {
  transform: rotate(135deg);
}

.ant-picker-date-panel .ant-picker-body {
  padding: 8px 12px;
}

.ant-picker-content {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.ant-picker-date-panel .ant-picker-content {
  width: 252px;
}

.ant-picker-cell {
  padding: 3px 0;
  color: rgb(0 0 0 / 25%);
  cursor: pointer;
}

.ant-picker-cell-in-view {
  color: rgb(0 0 0 / 65%);
}

.ant-picker-cell-disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.ant-picker-content th,
.ant-picker-content td {
  position: relative;
  min-width: 24px;
  font-weight: 400;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range {
  position: relative;
}

.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 2px;
  transition: background 0.3s, border 0.3s;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #067786;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  left: 50%;
}

.ant-picker-cell::before {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 1;
  height: 24px;
  transform: translateY(-50%);
  transform: translateY(-50%);
  content: '';
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #067786;
  border-radius: 2px;
  content: '';
}

.ant-picker-cell-range-hover-start::after,
.ant-picker-cell-range-hover-end::after,
.ant-picker-cell-range-hover::after {
  right: 0;
  left: 2px;
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  left: 6px;
  border-left: 1px dashed #4cadba;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 6px;
  border-right: 1px dashed #4cadba;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  position: absolute;
  top: 50%;
  z-index: 0;
  height: 24px;
  border-top: 1px dashed #4cadba;
  border-bottom: 1px dashed #4cadba;
  transform: translateY(-50%);
  transform: translateY(-50%);
  content: '';
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #4cadba;
}

.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after {
  right: 0;
  left: -7px;
}

.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: #4cadba;
  content: '';
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: #4cadba;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end)
  .ant-picker-cell-inner {
  border-radius: 2px 0 0 2px;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start)
  .ant-picker-cell-inner {
  border-radius: 0 2px 2px 0;
}

.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after {
  right: -7px;
  left: 0;
}

.ant-picker-panel-rtl
  .ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after {
  right: 0;
  left: -7px;
}

.ant-picker-panel-rtl
  .ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after {
  right: -7px;
  left: 0;
}

.ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 50%;
}

.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 0;
  left: 50%;
}

.ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  left: 50%;
}

.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  right: 50%;
  left: 0;
}

.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
.ant-picker-panel-rtl
  tr
  > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-panel-rtl
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  right: 6px;
  left: 0;
  border-right: 1px dashed #4cadba;
  border-left: none;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
.ant-picker-panel-rtl
  tr
  > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-panel-rtl
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 0;
  left: 6px;
  border-right: none;
  border-left: 1px dashed #4cadba;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  left: 14px;
  border-left: 1px dashed #4cadba;
  border-radius: 2px 0 0 2px;
}

.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  right: 14px;
  border-right: 1px dashed #4cadba;
  border-radius: 0 2px 2px 0;
}

.ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  right: 14px;
  border-right: 1px dashed #4cadba;
  border-radius: 0 2px 2px 0;
}

.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  left: 14px;
  border-left: 1px dashed #4cadba;
  border-radius: 2px 0 0 2px;
}

.ant-picker-cell-disabled {
  color: rgb(0 0 0 / 25%);
  pointer-events: none;
}

/* END DATE PICKER */

/* START CALENDAR */

.rdrCalendarWrapper {
  box-sizing: border-box;
  background: #fff;
  display: inline-box;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  user-select: none;
  color: #000;
  font-size: 12px;
}

.rdrDateDisplay {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin: 0.833em;
}

.rdrDateDisplayItem {
  -webkit-box-flex: 1;
  flex: 1 1;
  width: 0;
  text-align: center;
  color: inherit;
  border-radius: 4px;
  background-color: rgb(255 255 255);
  box-shadow: 0 1px 2px 0 rgb(35 57 66 / 21%);
  border: 1px solid transparent;
}

.rdrDateDisplayItem + .rdrDateDisplayItem {
  margin-left: 0.833em;
}

.rdrDateDisplayItem input {
  text-align: inherit;
  cursor: pointer;
  height: 2.5em;
  line-height: 2.5em;
  border: 0;
  background: transparent;
  width: 100%;
  color: #849095;
}

.rdrDateDisplayItem input:disabled {
  cursor: default;
}

.rdrMonthAndYearWrapper {
  box-sizing: inherit;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  height: 60px;
  padding-top: 10px;
}

.rdrMonthAndYearPickers {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-weight: 600;
}

.rdrNextPrevButton {
  box-sizing: inherit;
  cursor: pointer;
  outline: none;
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 0.833em;
  padding: 0;
  border: 0;
  border-radius: 5px;
  background: #eff2f7;
}

.rdrMonths {
  display: flex;
}

.rdrMonthsVertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.rdrMonthsHorizontal > div > div > div {
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.rdrMonth {
  width: 27.667em;
  padding: 0 0.833em 1.666em;
}

.rdrWeekDays {
  display: flex;
  padding: 0 0.833em;
}

.rdrWeekDay {
  flex-basis: calc(100% / 7);
  box-sizing: inherit;
  text-align: center;
  font-weight: 400;
  line-height: 2.667em;
  color: rgb(132 144 149);
}

.rdrDays {
  display: flex;
  flex-wrap: wrap;
}

.rdrInfiniteMonths {
  overflow: auto;
}

.rdrDateRangeWrapper {
  user-select: none;
}

.rdrDateInput {
  position: relative;
}

.rdrDateInput input {
  outline: none;
}

.rdrDateInput .rdrWarning {
  position: absolute;
  font-size: 1.6em;
  line-height: 1.6em;
  top: 0;
  right: 0.25em;
  color: #f00;
}

.rdrDay {
  box-sizing: inherit;
  width: calc(100% / 7);
  position: relative;
  font: inherit;
  cursor: pointer;
  background: transparent;
  user-select: none;
  border: 0;
  padding: 0;
  line-height: 3em;
  height: 3em;
  text-align: center;
  color: #1d2429;
}

.rdrDayNumber {
  outline: 0;
  font-weight: 300;
  position: absolute;
  left: 0;
  right: 0;
  top: 5px;
  bottom: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.rdrDayNumber span {
  color: #1d2429;
}

.rdrDayDisabled {
  cursor: not-allowed;
  background-color: rgb(248 248 248);
}

@supports (-ms-ime-align: auto) {
  .rdrDay {
    flex-basis: 14.285% !important;
  }
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  pointer-events: none;
  background: currentcolor;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  bottom: 5px;
}

.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
  background: rgb(255 255 255 / 9%);
  position: absolute;
  top: 3px;
  left: 0;
  right: 0;
  bottom: 3px;
  pointer-events: none;
  border: 0 solid currentcolor;
  z-index: 1;
}

.rdrDateRangePickerWrapper {
  display: inline-box;
  display: inline-flex;
  user-select: none;
}

.rdrStaticRanges {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.rdrStaticRange {
  font-size: inherit;
  border: 0;
  cursor: pointer;
  display: block;
  outline: 0;
  border-bottom: 1px solid #eff2f7;
  padding: 0;
  background: #fff;
}

.rdrInputRange {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 5px 20px;
}

.rdrDateDisplayWrapper {
  background-color: rgb(239 242 247);
}

.rdrDateDisplayItemActive {
  border-color: currentcolor;
}

.rdrDateDisplayItemActive input {
  color: #7d888d;
}

.rdrMonthAndYearPickers select {
  appearance: none;
  border: 0;
  padding: 10px 30px 10px 10px;
  border-radius: 4px;
  outline: 0;
  color: #3e484f;
  background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>")
    no-repeat;
  background-position: right 8px center;
  cursor: pointer;
  text-align: center;
}

.rdrMonthAndYearPickers select:hover {
  background-color: rgb(0 0 0 / 7%);
}

.rdrMonthPicker,
.rdrYearPicker {
  margin: 0 5px;
}

.rdrNextPrevButton:hover {
  background: #e1e7f0;
}

.rdrNextPrevButton i {
  display: block;
  width: 0;
  height: 0;
  padding: 0;
  text-align: center;
  border-style: solid;
  margin: auto;
  transform: translate(-3px, 0);
}

.rdrPprevButton i {
  border-width: 4px 6px 4px 4px;
  border-color: transparent rgb(52 73 94) transparent transparent;
  transform: translate(-3px, 0);
}

.rdrNextButton i {
  margin: 0 0 0 7px;
  border-width: 4px 4px 4px 6px;
  border-color: transparent transparent transparent rgb(52 73 94);
  transform: translate(3px, 0);
}

.rdrMonth .rdrWeekDays {
  padding: 0;
}

.rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName {
  display: none;
}

.rdrDay:focus {
  outline: 0;
}

.rdrDayToday .rdrDayNumber span {
  font-weight: 500;
}

.rdrDayToday .rdrDayNumber span::after {
  content: '';
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 18px;
  height: 2px;
  border-radius: 2px;
  background: #3d91ff;
}

.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span::after,
.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span::after,
.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span::after,
.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span::after {
  background: #fff;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: rgb(255 255 255 / 85%);
}

.rdrSelected {
  left: 2px;
  right: 2px;
  border-radius: 1.042em;
}

.rdrStartEdge {
  border-top-left-radius: 1.042em;
  border-bottom-left-radius: 1.042em;
  left: 2px;
}

.rdrEndEdge {
  border-top-right-radius: 1.042em;
  border-bottom-right-radius: 1.042em;
  right: 2px;
}

.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrEndEdge,
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrEndEdge {
  border-top-left-radius: 1.042em;
  border-bottom-left-radius: 1.042em;
  left: 2px;
}

.rdrDayEndOfMonth .rdrInRange,
.rdrDayEndOfMonth .rdrStartEdge,
.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrStartEdge {
  border-top-right-radius: 1.042em;
  border-bottom-right-radius: 1.042em;
  right: 2px;
}

.rdrDayStartOfMonth .rdrDayInPreview,
.rdrDayStartOfMonth .rdrDayEndPreview,
.rdrDayStartOfWeek .rdrDayInPreview,
.rdrDayStartOfWeek .rdrDayEndPreview {
  border-top-left-radius: 1.333em;
  border-bottom-left-radius: 1.333em;
  border-left-width: 1px;
  left: 0;
}

.rdrDayEndOfMonth .rdrDayInPreview,
.rdrDayEndOfMonth .rdrDayStartPreview,
.rdrDayEndOfWeek .rdrDayInPreview,
.rdrDayEndOfWeek .rdrDayStartPreview {
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  border-right-width: 1px;
  right: 0;
}

.rdrDayStartPreview {
  border-top-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-top-left-radius: 1.333em;
  border-bottom-left-radius: 1.333em;
  left: 0;
}

.rdrDayInPreview {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.rdrDayEndPreview {
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  right: 2px;
  right: 0;
}

.rdrDefinedRangesWrapper {
  font-size: 12px;
  width: 226px;
  border-right: solid 1px #eff2f7;
  background: #fff;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  color: currentcolor;
  font-weight: 600;
}

.rdrStaticRange:hover .rdrStaticRangeLabel,
.rdrStaticRange:focus .rdrStaticRangeLabel {
  background: #eff2f7;
}

.rdrStaticRangeLabel {
  display: block;
  outline: 0;
  line-height: 18px;
  padding: 10px 20px;
  text-align: left;
}

.rdrInputRanges {
  padding: 10px 0;
}

.rdrInputRangeInput {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  text-align: center;
  border: solid 1px rgb(222 231 235);
  margin-right: 10px;
  color: rgb(108 118 122);
}

.rdrInputRangeInput:focus,
.rdrInputRangeInput:hover {
  border-color: rgb(180 191 196);
  outline: 0;
  color: #333;
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber::after {
  content: '';
  border: 1px solid currentcolor;
  border-radius: 1.333em;
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: 0;
  right: 0;
  background: transparent;
}

.rdrDayPassive {
  pointer-events: none;
}

.rdrDayPassive .rdrDayNumber span {
  color: #d5dce0;
}

.rdrDayPassive .rdrInRange,
.rdrDayPassive .rdrStartEdge,
.rdrDayPassive .rdrEndEdge,
.rdrDayPassive .rdrSelected,
.rdrDayPassive .rdrDayStartPreview,
.rdrDayPassive .rdrDayInPreview,
.rdrDayPassive .rdrDayEndPreview {
  display: none;
}

.rdrDayDisabled .rdrDayNumber span {
  color: #aeb9bf;
}

.rdrDayDisabled .rdrInRange,
.rdrDayDisabled .rdrStartEdge,
.rdrDayDisabled .rdrEndEdge,
.rdrDayDisabled .rdrSelected,
.rdrDayDisabled .rdrDayStartPreview,
.rdrDayDisabled .rdrDayInPreview,
.rdrDayDisabled .rdrDayEndPreview {
  filter: grayscale(100%) opacity(60%);
}

.rdrMonthName {
  text-align: left;
  font-weight: 600;
  color: #849095;
  padding: 0.833em;
}

/* END CALENDAR */
